<template>
  <div>
    <!-- tab栏 -->
    <!-- <van-tabs @click="onClickOnlyOne">
      <van-tab title="社区活动"> -->
    <div class="information">
      <!-- 全部 -->
      <div class="center" v-for="item in list" :key="item.index">
        <router-link
          :to="'/activityLog/detail/' + item.AId + '/' + item.AKCate"
        >
          <div
            class="Content"
            style="
              border-bottom: 1px solid rgb(245 243 243);
              position: relative;
            "
          >
            <div
              style="display: inline-block; width: 32%"
              class="ContentA"
              ref="getHeight"
            >
              <span v-if="item.AImg && item.AImg != ''">
                <img
                  :src="item.AImg"
                  alt=""
                  style="min-height: 80px; height: 100px"
                />
              </span>
              <span v-else>
                <img
                  src="http://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png"
                  alt=""
                  style="min-height: 80px; height: 100px"
                />
              </span>
            </div>
            <div class="ContentB">
              <div class="centerTitle van-ellipsis--l2">
                {{ item.Title }}
              </div>
              <div
                class="centertime van-multi-ellipsis--l2"
                style="margin: 28px 0px; font-size: 13px; color: #666666"
              >
                {{ item.Summary }}
              </div>
              <div class="centertime">
                <span>{{ item.IssTime | timeFilter }}</span>
                <!-- <span style="padding-left:10px;"> 阅读 {{item.ReadNum}}</span> -->
              </div>
            </div>
          </div>
          <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content">
            <div style="display: inline-block" class="ContentA">
              <img :src="item.AImg" alt="" />
            </div>
            <div style="display: inline-block" class="ContentB">
              <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
              <br />
              <span class="centertime">{{ item.ADate }}</span>
            </div>
          </div> -->
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">── 没有更多数据了 ──</div>
    </div>
    <!-- </van-tab> -->
    <!-- <van-tab title="党组活动">
        <div class="information">
          <div class="center" v-for="item in lists" :key="item.index">
            <router-link :to="'/activityLog/detail/' + item.AId + '/2'">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImg" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <van-tab title="志愿者活动">
        <div class="information">
          <div class="center" v-for="item in lists" :key="item.index">
            <router-link :to="'/activityLog/detail/' + item.AId + '/2'">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImg" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab> -->
    <!-- </van-tabs> -->
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WxGetMySignupActivityPage,
  WxGetMySignupActivity,
} from "@/api/Activity";
import { Toast } from "vant";
export default {
  data() {
    return {
      akId: 0, //tab栏
      list: {}, //资讯列表
      lists: {},
      listfrom: {
        page: 1,
        limit: 10,
        // status: 0,
        // akId: 0,
        openID: this.$route.query["openid"],
      }, //查询传参
      hidden: true, //没有更多数据了
      listfroms: {
        page: 1,
        limit: 10,
        status: 0,
        openID: this.$route.query["openid"],
      },
    };
  },
  filters: {
    timeFilter(value) {
      if (value != undefined) {
        let gstime = value.replace("T", " ");
        // let gstime = value.substring(0, 10);
        return gstime;
      }
    },
  },
  methods: {
    onClickOnlyOne(name, title) {
      console.log(name);
      if (name == "0") {
        this.list = [];
        this.listfrom.page = 1;
        this.listfrom.limit = 10;
        this.listfrom.status = 0;
        this.listfrom.akId = 0;
        this.listfrom.openID = this.$route.query["openid"];
        this.getList();
      }
      if (name == "1") {
        this.lists = [];
        this.listfroms.page = 1;
        this.listfroms.limit = 10;
        this.listfroms.status = 0;
        this.listfroms.openID = this.$route.query["openid"];
        this.getLists();
      }
      if (name == "2") {
        this.list = [];
        this.listfrom.page = 1;
        this.listfrom.limit = 10;
        this.listfrom.status = 0;
        this.listfrom.akId = 1;
        this.listfrom.openID = this.$route.query["openid"];
        this.getList();
      }
    },
    // 获取分页列表
    getList() {
      WxGetMySignupActivityPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImg == "") {
                  this.list[i].AImg = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => {});
    },
    getLists() {
      WxGetMySignupActivity(this.listfroms)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.lists = {};
            } else {
              this.lists = res.data.data;
              for (var i = 0; i < this.lists.length; i++) {
                if (this.lists[i].AImg == "") {
                  this.lists[i].AImg = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => {});
    },
    // tan点击切换事件
    onClick(e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.ContentB {
  display: inline-block;
  width: 62% !important;
  margin-left: 3% !important;
}

.bgcolor {
  position: absolute;
  /* background: linear-gradient(#f63e3b, #fff); */
  /* background: #f63e3b; */
  height: 150px;
  width: 100%;
}

.pageview {
  min-height: 100%;
  background: #fff;
}

.homebanner {
  width: 95%;
  margin: auto;
  margin-top: 15px;
}

.homebanner img {
  width: 100%;
  vertical-align: top;
  border-radius: 5px;
}

.wktime {
  padding: 0 20px;
}

.wktype {
  padding: 0 5px 5px;
}

.orginview .iconimg {
  width: 62%;
}

.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}

.wktype .iconimg {
  width: 60%;
}

.wktype .wktext {
  margin-top: 8px;
  font-size: 0.75rem;
}

.grouptitle {
  /* text-indent: 5px;
  font-size: 18px;
  color: #231815;
  font-weight: 700; */
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
}

.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}

.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fuwenben p {
  margin: 0 !important;
}

.moreCount {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-right: 4px;
}
.title {
  position: absolute;
  bottom: 25px;
  left: 20%;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
}
.homebanner .shou {
  width: 17.8%;
  position: absolute;
  top: 52%;
  right: 10.8%;
  transform: translateY(-50%);
}

.left,
.right {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  font-style: italic;
  color: #ffffff;
}

.left {
  font-weight: 100;
}

.ContentB .centerTitle {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231815;
}
.ContentB .centertime {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>